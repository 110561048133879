
import AppLogo from '@/components/AppLogo.vue'
import QForm from 'quasar/src/components/form/QForm.js';
import { defineComponent, ref, reactive } from 'vue'
import { requiredRule } from '@/common/formValidationRules'
import { useProfile } from '@/composables/useProfile'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'SignIn',

  components: {
    AppLogo,
  },

  setup() {
    const router = useRouter()
    const showViewSelector = ref(false)
    const signInForm = ref<QForm | null>(null)
    const isSignInError = ref(false)
    const model = reactive({
      email: '',
      password: '',
    })
    const showPassword = ref(false)
    const { signIn, signInPending, fetchUserInfo, can, profile } = useProfile()

    function routeDefaultByPermission() {
      if (can('mobile.view') && can('backoffice.view')) {
        showViewSelector.value = true
      } else {
        if (can('mobile.view')) {
          router.push('/mobile')
        }
        if (can('backoffice.view')) {
          router.push('/backoffice')
        }
      }
    }

    if (profile.value) {
      routeDefaultByPermission()
    }

    async function onSignIn() {
      isSignInError.value = false
      const isValid = await signInForm.value?.validate()
      if (!isValid) return
      try {
        await signIn(model)
        await fetchUserInfo()

        routeDefaultByPermission()
      } catch (error) {
        isSignInError.value = true
      }
    }

    return {
      showPassword,
      signInForm,
      signInPending,
      requiredRule,
      model,
      onSignIn,
      isSignInError,
      showViewSelector,
      profile,
      router,
    }
  },
})
