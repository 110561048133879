<template>
  <q-layout>
    <q-page-container>
      <q-page
        :class="[
          'flex justify-center items-center q-pa-sm',
          $q.screen.lt.sm ? $style.gradient : 'bg-dark',
        ]"
      >
        <template v-if="$q.screen.lt.sm || showViewSelector">
          <div :class="[$style.logoMobile, 'flex items-center']">
            <AppLogo color="white" />
            <q-separator vertical color="white" class="q-ml-sm" />
            <h5 class="q-ml-md q-ma-none text-weight-medium text-white">
              Klarbuss
            </h5>
          </div>
        </template>
        <transition
          enter-active-class="animated animate__fadeInDown"
          leave-active-class="animated animate__fadeOutDown"
          mode="out-in"
          :duration="70"
        >
          <q-card
            class="full-width shadow-20"
            style="max-width: 400px"
            v-if="showViewSelector"
          >
            <q-card-section horizontal class="q-pa-none">
              <template
                v-for="(permission, $i) in profile.permissions"
                :key="permission"
              >
                <q-card-section
                  style="height: 100px"
                  class="
                    q-pa-none
                    flex
                    items-center
                    justify-center
                    full-width
                    text-weight-bold
                  "
                >
                  <q-btn
                    size="17px"
                    no-caps
                    stretch
                    flat
                    class="full-width"
                    stack
                    @click="
                      router.push(
                        permission.includes('backoffice')
                          ? '/backoffice'
                          : '/mobile'
                      )
                    "
                    :color="
                      permission.includes('backoffice')
                        ? 'primary'
                        : 'secondary'
                    "
                    :icon="
                      permission.includes('backoffice')
                        ? 'mdi-desktop-mac'
                        : 'mdi-cellphone'
                    "
                  >
                    {{
                      permission.includes('backoffice')
                        ? 'Backoffice'
                        : 'Tvättlista mobil'
                    }}
                  </q-btn>
                </q-card-section>
                <q-separator v-if="$i === 0" vertical />
              </template>
            </q-card-section>
            <div
              style="
                position: absolute;
                bottom: -30px;
                left: 0;
                right: 0;
                opacity: 0.8;
              "
              class="full-width flex justify-center text-white"
            >
              Välj vy för att gå vidare
            </div>
          </q-card>
          <q-card
            class="full-width shadow-20"
            style="max-width: 600px"
            square
            v-else
          >
            <q-card-section horizontal>
              <q-card-section
                :class="[$style.gradient, 'gt-xs']"
                style="width: 450px"
              >
                <div class="column full-height">
                  <div class="flex items-center">
                    <AppLogo color="white" />
                    <q-separator vertical color="white" class="q-ml-sm" />
                    <h5 class="q-ml-md q-ma-none text-weight-medium text-white">
                      Klarbuss
                    </h5>
                  </div>

                  <q-space />

                  <div class="text-white text-caption">Powered by Reges</div>
                </div>
              </q-card-section>
              <q-card-section class="full-width">
                <div class="text-h6 q-mb-lg flex items-center">Logga in</div>
                <q-form ref="signInForm" @submit="onSignIn">
                  <div class="row">
                    <div class="col">
                      <q-input
                        v-model="model.email"
                        label="E-mail"
                        autocomplete="username"
                        :rules="[requiredRule]"
                        clearable
                      >
                        <template v-slot:prepend
                          ><q-icon name="mdi-account"
                        /></template>
                      </q-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <q-input
                        v-model="model.password"
                        autocomplete="current-password"
                        label="Lösenord"
                        :type="!showPassword ? 'password' : 'text'"
                        :rules="[requiredRule]"
                      >
                        <template v-slot:prepend
                          ><q-icon name="mdi-lock"
                        /></template>

                        <template v-slot:append>
                          <q-icon
                            :name="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            class="cursor-pointer"
                            @click="showPassword = !showPassword"
                          />
                        </template>
                      </q-input>
                    </div>
                  </div>
                  <q-slide-transition>
                    <div class="row" v-if="isSignInError">
                      <div class="col q-mb-sm">
                        <q-banner dense class="text-white bg-red" rounded>
                          <template v-slot:avatar>
                            <q-icon
                              size="xs"
                              name="mdi-alert-circle-outline"
                              color="white"
                            />
                          </template>
                          Det gick inte logga in!
                        </q-banner>
                      </div>
                    </div>
                  </q-slide-transition>
                  <div class="row">
                    <div class="col justify-end flex">
                      <q-btn
                        color="primary"
                        label="Logga in"
                        type="submit"
                        :loading="signInPending"
                      />
                    </div>
                  </div>
                </q-form>
              </q-card-section>
            </q-card-section>
          </q-card>
        </transition>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import AppLogo from '@/components/AppLogo.vue'
import { QForm } from 'quasar'
import { defineComponent, ref, reactive } from 'vue'
import { requiredRule } from '@/common/formValidationRules'
import { useProfile } from '@/composables/useProfile'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'SignIn',

  components: {
    AppLogo,
  },

  setup() {
    const router = useRouter()
    const showViewSelector = ref(false)
    const signInForm = ref<QForm | null>(null)
    const isSignInError = ref(false)
    const model = reactive({
      email: '',
      password: '',
    })
    const showPassword = ref(false)
    const { signIn, signInPending, fetchUserInfo, can, profile } = useProfile()

    function routeDefaultByPermission() {
      if (can('mobile.view') && can('backoffice.view')) {
        showViewSelector.value = true
      } else {
        if (can('mobile.view')) {
          router.push('/mobile')
        }
        if (can('backoffice.view')) {
          router.push('/backoffice')
        }
      }
    }

    if (profile.value) {
      routeDefaultByPermission()
    }

    async function onSignIn() {
      isSignInError.value = false
      const isValid = await signInForm.value?.validate()
      if (!isValid) return
      try {
        await signIn(model)
        await fetchUserInfo()

        routeDefaultByPermission()
      } catch (error) {
        isSignInError.value = true
      }
    }

    return {
      showPassword,
      signInForm,
      signInPending,
      requiredRule,
      model,
      onSignIn,
      isSignInError,
      showViewSelector,
      profile,
      router,
    }
  },
})
</script>

<style lang="scss" module>
@import '@/styles/quasar.variables.scss';

.gradient {
  background: linear-gradient($primary, $accent);
}

.logoMobile {
  position: absolute;
  top: 16px;
  left: 16px;
}
</style>
